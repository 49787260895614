<template>
    <div>    <img src="@/assets/solutionpdf/data_1.jpg" alt="" />
    <img src="@/assets/solutionpdf/data_2.jpg" alt="" />
    <img src="@/assets/solutionpdf/data_3.jpg" alt="" />
    <img src="@/assets/solutionpdf/data_4.jpg" alt="" />
    <img src="@/assets/solutionpdf/data_5.jpg" alt="" />
    <img src="@/assets/solutionpdf/data_6.jpg" alt="" />
    <img src="@/assets/solutionpdf/data_7.jpg" alt="" />
    </div>
</template>

<script>
export default {
    name: "data-solution"
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>